// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as $$Date from "../libs/Date.res.js";
import * as Role from "./Role.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as Routes_Country from "../routes/common/Routes_Country.res.js";
import * as Routes_Location from "../routes/common/Routes_Location.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as PreloadedSearchLocation from "./PreloadedSearchLocation.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var administriveDivisions = [
  "AutonomousRegion",
  "Canton",
  "Department",
  "Emirate",
  "FederalDistrict",
  "Governorate",
  "Land",
  "Municipality",
  "Oblast",
  "Prefecture",
  "Province",
  "Region",
  "Republic",
  "Territory",
  "State",
  "Voivodeship"
];

function divisionToString(administrativeDivision) {
  switch (administrativeDivision) {
    case "AutonomousRegion" :
        return "Autonomous Region";
    case "Canton" :
        return "Canton";
    case "Department" :
        return "Department";
    case "Emirate" :
        return "Emirate";
    case "FederalDistrict" :
        return "Federal District";
    case "Governorate" :
        return "Governorate";
    case "Land" :
        return "Land";
    case "Municipality" :
        return "Municipality";
    case "Oblast" :
        return "Oblast";
    case "Prefecture" :
        return "Prefecture";
    case "Province" :
        return "Province";
    case "Region" :
        return "Region";
    case "Republic" :
        return "Republic";
    case "Territory" :
        return "Territory";
    case "State" :
        return "State";
    case "Voivodeship" :
        return "Voivodeship";
    
  }
}

function divisionToStringPlural(administrativeDivision) {
  switch (administrativeDivision) {
    case "AutonomousRegion" :
        return "Autonomous Regions";
    case "Canton" :
        return "Cantons";
    case "Department" :
        return "Departments";
    case "Emirate" :
        return "Emirates";
    case "FederalDistrict" :
        return "Federal Districts";
    case "Governorate" :
        return "Governorates";
    case "Land" :
        return "Lands";
    case "Municipality" :
        return "Municipalities";
    case "Oblast" :
        return "Oblasts";
    case "Prefecture" :
        return "Prefectures";
    case "Province" :
        return "Provinces";
    case "Region" :
        return "Regions";
    case "Republic" :
        return "Republics";
    case "Territory" :
        return "Territories";
    case "State" :
        return "States";
    case "Voivodeship" :
        return "Voivodeships";
    
  }
}

function divisionFromString(string) {
  switch (string) {
    case "Autonomous Region" :
        return "AutonomousRegion";
    case "Canton" :
        return "Canton";
    case "Department" :
        return "Department";
    case "Emirate" :
        return "Emirate";
    case "Federal District" :
        return "FederalDistrict";
    case "Governorate" :
        return "Governorate";
    case "Land" :
        return "Land";
    case "Municipality" :
        return "Municipality";
    case "Oblast" :
        return "Oblast";
    case "Prefecture" :
        return "Prefecture";
    case "Province" :
        return "Province";
    case "Region" :
        return "Region";
    case "Republic" :
        return "Republic";
    case "State" :
        return "State";
    case "Territory" :
        return "Territory";
    case "Voivodeship" :
        return "Voivodeship";
    default:
      return PervasivesU.failwith("Unknown administrative division");
  }
}

function fromJs(js) {
  return {
          id: js.id,
          name: js.name,
          slug: js.slug,
          active: js.active,
          metaTitle: js.metaTitle,
          metaDescription: js.metaDescription,
          altNames: js.altNames,
          description: js.description,
          cityState: js.cityState,
          administrativeDivision: divisionFromString(js.administrativeDivision),
          administrativeDivisionPlural: js.administrativeDivisionPlural,
          createdAt: $$Date.Naive.fromString(js.createdAt),
          updatedAt: $$Date.Naive.fromString(js.updatedAt)
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              metaTitle: field.required("metaTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string),
              altNames: field.required("altNames", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              cityState: field.required("cityState", Json_Decode$JsonCombinators.bool),
              administrativeDivision: divisionFromString(field.required("administrativeDivision", Json_Decode$JsonCombinators.string)),
              administrativeDivisionPlural: field.required("administrativeDivisionPlural", Json_Decode$JsonCombinators.string),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function toJson(active) {
  if (active === "Active") {
    return true;
  } else {
    return false;
  }
}

function toString(value) {
  if (value === "Active") {
    return "Active";
  } else {
    return "Inactive";
  }
}

function fromJs$1(active) {
  if (active) {
    return "Active";
  } else {
    return "Inactive";
  }
}

function fromBool(active) {
  if (active) {
    return "Active";
  } else {
    return "Inactive";
  }
}

var Active = {
  toJson: toJson,
  toString: toString,
  fromJs: fromJs$1,
  fromBool: fromBool
};

function fromJs$2(js) {
  return {
          id: js.id,
          name: js.name,
          slug: js.slug,
          totalLocations: js.totalLocations,
          totalStates: js.totalStates,
          totalCities: js.totalCities
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int),
              totalStates: field.required("totalStates", Json_Decode$JsonCombinators.$$int),
              totalCities: field.required("totalCities", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var Tile = {
  fromJs: fromJs$2,
  decoder: decoder$1,
  fromJson: fromJson$1
};

function fromJs$3(js) {
  return {
          id: js.id,
          name: js.name,
          slug: js.slug,
          totalLocations: js.totalLocations
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

var ListItem = {
  fromJs: fromJs$3,
  decoder: decoder$2,
  fromJson: fromJson$2
};

function fromJs$4(js) {
  return {
          countries: Belt_Array.map(js.countries, fromJs$3)
        };
}

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              countries: field.required("countries", Json_Decode$JsonCombinators.array(decoder$2))
            };
    });

function fromJson$3(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

var List = {
  fromJs: fromJs$4,
  decoder: decoder$3,
  fromJson: fromJson$3
};

function fromJs$5(js) {
  return {
          countries: Belt_Array.map(js.countries, (function (country) {
                  return fromJs$2(country);
                })),
          totalCountries: js.totalCountries,
          totalPages: js.totalPages,
          currentPage: js.currentPage,
          offset: js.offset,
          totalLocations: js.totalLocations,
          totalStates: js.totalStates,
          totalCities: js.totalCities
        };
}

var decoder$4 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              countries: field.required("countries", Json_Decode$JsonCombinators.array(decoder$1)),
              totalCountries: field.required("totalCountries", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              offset: field.required("offset", Json_Decode$JsonCombinators.$$int),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int),
              totalStates: field.required("totalStates", Json_Decode$JsonCombinators.$$int),
              totalCities: field.required("totalCities", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$4(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$4));
}

var Index = {
  fromJs: fromJs$5,
  decoder: decoder$4,
  fromJson: fromJson$4
};

function fromJs$6(js) {
  return {
          id: js.id,
          name: js.name,
          slug: js.slug,
          code: js.code,
          active: js.active,
          metaTitle: js.metaTitle,
          metaDescription: js.metaDescription,
          altNames: js.altNames,
          description: js.description,
          locationsCount: js.locationsCount,
          locationsCountActive: js.locationsCountActive,
          citiesCount: js.citiesCount,
          citiesCountActive: js.citiesCountActive,
          statesCount: js.statesCount,
          statesCountActive: js.statesCountActive,
          cityState: js.cityState,
          administrativeDivision: divisionFromString(js.administrativeDivision),
          createdAt: $$Date.Naive.fromString(js.createdAt),
          updatedAt: $$Date.Naive.fromString(js.updatedAt)
        };
}

var decoder$5 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              code: field.required("code", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              metaTitle: field.required("metaTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string),
              altNames: field.required("altNames", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              locationsCount: field.required("locationsCount", Json_Decode$JsonCombinators.$$int),
              locationsCountActive: field.required("locationsCountActive", Json_Decode$JsonCombinators.$$int),
              citiesCount: field.required("citiesCount", Json_Decode$JsonCombinators.$$int),
              citiesCountActive: field.required("citiesCountActive", Json_Decode$JsonCombinators.$$int),
              statesCount: field.required("statesCount", Json_Decode$JsonCombinators.$$int),
              statesCountActive: field.required("statesCountActive", Json_Decode$JsonCombinators.$$int),
              cityState: field.required("cityState", Json_Decode$JsonCombinators.bool),
              administrativeDivision: divisionFromString(field.required("administrativeDivision", Json_Decode$JsonCombinators.string)),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder)
            };
    });

function fromJson$5(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$5));
}

var Dashboard = {
  fromJs: fromJs$6,
  decoder: decoder$5,
  fromJson: fromJson$5
};

var decoder$6 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              administrativeDivision: field.required("administrativeDivision", Json_Decode$JsonCombinators.string),
              administrativeDivisionPlural: field.required("administrativeDivisionPlural", Json_Decode$JsonCombinators.string),
              latitude: field.required("latitude", Json_Decode$JsonCombinators.$$float),
              longitude: field.required("longitude", Json_Decode$JsonCombinators.$$float),
              locations: field.required("locations", PreloadedSearchLocation.Index.decoder)
            };
    });

function fromJson$6(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$6));
}

function breadcrumbs(country) {
  var match = Role.fromString(country.locations.userRole);
  if (match === "Admin") {
    return [
            {
              linkName: "Home",
              linkPath: "/"
            },
            {
              linkName: "All Data Centers",
              linkPath: Routes_Location.index
            },
            {
              linkName: "Countries",
              linkPath: Routes_Country.index
            },
            {
              linkName: country.name,
              linkPath: Routes_Country.show(country.slug)
            },
            {
              linkName: "Edit",
              linkPath: Routes_Country.Dashboard.edit(country.id)
            }
          ];
  } else {
    return [
            {
              linkName: "Home",
              linkPath: "/"
            },
            {
              linkName: "All Data Centers",
              linkPath: Routes_Location.index
            },
            {
              linkName: "Countries",
              linkPath: Routes_Country.index
            },
            {
              linkName: country.name,
              linkPath: Routes_Country.show(country.slug)
            }
          ];
  }
}

var decoder$7 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              preloadedSearchLocations: field.required("preloadedSearchLocations", Json_Decode$JsonCombinators.array(PreloadedSearchLocation.Index.PreloadedSearchLocation.decoder))
            };
    });

var $$Map = {
  decoder: decoder$7
};

var Show = {
  decoder: decoder$6,
  fromJson: fromJson$6,
  breadcrumbs: breadcrumbs,
  $$Map: $$Map
};

var Edit;

export {
  administriveDivisions ,
  divisionToString ,
  divisionToStringPlural ,
  divisionFromString ,
  fromJs ,
  decoder ,
  fromJson ,
  Active ,
  Tile ,
  ListItem ,
  List ,
  Index ,
  Dashboard ,
  Edit ,
  Show ,
}
/* decoder Not a pure module */
