// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as $$Date from "../libs/Date.res.js";
import * as Role from "./Role.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Routes_State from "../routes/common/Routes_State.res.js";
import * as Routes_Country from "../routes/common/Routes_Country.res.js";
import * as Routes_Location from "../routes/common/Routes_Location.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as PreloadedSearchLocation from "./PreloadedSearchLocation.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          countryId: js.countryId,
          name: js.name,
          slug: js.slug,
          active: js.active,
          metaTitle: js.metaTitle,
          metaDescription: js.metaDescription,
          altNames: js.altNames,
          description: js.description,
          createdAt: $$Date.Naive.fromString(js.createdAt),
          updatedAt: $$Date.Naive.fromString(js.updatedAt)
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              countryId: field.required("countryId", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              metaTitle: field.required("metaTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string),
              altNames: field.required("altNames", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function toJson(active) {
  if (active === "Active") {
    return true;
  } else {
    return false;
  }
}

function toString(value) {
  if (value === "Active") {
    return "Active";
  } else {
    return "Inactive";
  }
}

function fromJs$1(active) {
  if (active) {
    return "Active";
  } else {
    return "Inactive";
  }
}

function fromBool(active) {
  if (active) {
    return "Active";
  } else {
    return "Inactive";
  }
}

var Active = {
  toJson: toJson,
  toString: toString,
  fromJs: fromJs$1,
  fromBool: fromBool
};

function fromJs$2(js) {
  return {
          id: js.id,
          name: js.name,
          slug: js.slug,
          countryName: js.countryName,
          countrySlug: js.countrySlug,
          totalLocations: js.totalLocations,
          totalCities: js.totalCities
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              countryName: field.required("countryName", Json_Decode$JsonCombinators.string),
              countrySlug: field.required("countrySlug", Json_Decode$JsonCombinators.string),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int),
              totalCities: field.required("totalCities", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var Tile = {
  fromJs: fromJs$2,
  decoder: decoder$1,
  fromJson: fromJson$1
};

function fromJs$3(js) {
  return {
          states: Belt_Array.map(js.states, (function (state) {
                  return fromJs$2(state);
                })),
          totalStates: js.totalStates,
          totalPages: js.totalPages,
          currentPage: js.currentPage,
          offset: js.offset,
          totalCities: js.totalCities,
          totalCountries: js.totalCountries,
          totalLocations: js.totalLocations
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              states: field.required("states", Json_Decode$JsonCombinators.array(decoder$1)),
              totalStates: field.required("totalStates", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              offset: field.required("offset", Json_Decode$JsonCombinators.$$int),
              totalCities: field.required("totalCities", Json_Decode$JsonCombinators.$$int),
              totalCountries: field.required("totalCountries", Json_Decode$JsonCombinators.$$int),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

var Index = {
  fromJs: fromJs$3,
  decoder: decoder$2,
  fromJson: fromJson$2
};

function fromJs$4(js) {
  return {
          name: js.name,
          slug: js.slug,
          totalLocations: js.totalLocations
        };
}

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$3(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

var Sidebar = {
  fromJs: fromJs$4,
  decoder: decoder$3,
  fromJson: fromJson$3
};

function fromJs$5(js) {
  return {
          states: Belt_Array.map(js.states, (function (provider) {
                  return fromJs$4(provider);
                })),
          currentPage: js.currentPage,
          totalPages: js.totalPages,
          offset: js.offset
        };
}

var decoder$4 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              states: field.required("states", Json_Decode$JsonCombinators.array(decoder$3)),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              offset: field.required("offset", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$4(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$4));
}

var SidebarTable = {
  fromJs: fromJs$5,
  decoder: decoder$4,
  fromJson: fromJson$4
};

var decoder$5 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$5(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$5));
}

var ListItem = {
  decoder: decoder$5,
  fromJson: fromJson$5
};

var decoder$6 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              states: field.required("states", Json_Decode$JsonCombinators.array(decoder$5))
            };
    });

function fromJson$6(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$6));
}

var List = {
  decoder: decoder$6,
  fromJson: fromJson$6
};

function fromJs$6(js) {
  return {
          id: js.id,
          countryId: js.countryId,
          name: js.name,
          countryName: js.countryName,
          slug: js.slug,
          countrySlug: js.countrySlug,
          code: js.code,
          active: js.active,
          metaTitle: js.metaTitle,
          metaDescription: js.metaDescription,
          altNames: js.altNames,
          description: js.description,
          locationsCount: js.locationsCount,
          locationsCountActive: js.locationsCountActive,
          citiesCount: js.citiesCount,
          citiesCountActive: js.citiesCountActive,
          createdAt: $$Date.Naive.fromString(js.createdAt),
          updatedAt: $$Date.Naive.fromString(js.updatedAt)
        };
}

var decoder$7 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              countryId: field.required("countryId", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              countryName: field.required("countryName", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              countrySlug: field.required("countrySlug", Json_Decode$JsonCombinators.string),
              code: field.required("code", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              metaTitle: field.required("metaTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string),
              altNames: field.required("altNames", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              locationsCount: field.required("locationsCount", Json_Decode$JsonCombinators.$$int),
              locationsCountActive: field.required("locationsCountActive", Json_Decode$JsonCombinators.$$int),
              citiesCount: field.required("citiesCount", Json_Decode$JsonCombinators.$$int),
              citiesCountActive: field.required("citiesCountActive", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder)
            };
    });

function fromJson$7(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$7));
}

var Dashboard = {
  fromJs: fromJs$6,
  decoder: decoder$7,
  fromJson: fromJson$7
};

var decoder$8 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$8(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$8));
}

var Country = {
  decoder: decoder$8,
  fromJson: fromJson$8
};

var decoder$9 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              administrativeDivision: field.required("administrativeDivision", Json_Decode$JsonCombinators.string),
              administrativeDivisionPlural: field.required("administrativeDivisionPlural", Json_Decode$JsonCombinators.string),
              latitude: field.required("latitude", Json_Decode$JsonCombinators.$$float),
              longitude: field.required("longitude", Json_Decode$JsonCombinators.$$float),
              locations: field.required("locations", PreloadedSearchLocation.Index.decoder),
              country: field.required("country", decoder$8)
            };
    });

function fromJson$9(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$9));
}

function breadcrumbs(state) {
  var match = Role.fromString(state.locations.userRole);
  if (match === "Admin") {
    return [
            {
              linkName: "Home",
              linkPath: "/"
            },
            {
              linkName: "All Data Centers",
              linkPath: Routes_Location.index
            },
            {
              linkName: "Countries",
              linkPath: Routes_Country.index
            },
            {
              linkName: state.country.name,
              linkPath: Routes_Country.show(state.country.slug)
            },
            {
              linkName: state.name,
              linkPath: Routes_State.show(state.slug, state.country.slug)
            },
            {
              linkName: "Edit",
              linkPath: Routes_State.Dashboard.edit(state.id)
            }
          ];
  } else {
    return [
            {
              linkName: "Home",
              linkPath: "/"
            },
            {
              linkName: "All Data Centers",
              linkPath: Routes_Location.index
            },
            {
              linkName: "Countries",
              linkPath: Routes_Country.index
            },
            {
              linkName: state.country.name,
              linkPath: Routes_Country.show(state.country.slug)
            },
            {
              linkName: state.name,
              linkPath: Routes_State.show(state.slug, state.country.slug)
            }
          ];
  }
}

var decoder$10 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              preloadedSearchLocations: field.required("preloadedSearchLocations", Json_Decode$JsonCombinators.array(PreloadedSearchLocation.Index.PreloadedSearchLocation.decoder))
            };
    });

var $$Map = {
  decoder: decoder$10
};

var Show = {
  Country: Country,
  decoder: decoder$9,
  fromJson: fromJson$9,
  breadcrumbs: breadcrumbs,
  $$Map: $$Map
};

var Edit;

export {
  fromJs ,
  decoder ,
  fromJson ,
  Active ,
  Tile ,
  Index ,
  Sidebar ,
  SidebarTable ,
  ListItem ,
  List ,
  Dashboard ,
  Edit ,
  Show ,
}
/* decoder Not a pure module */
